<template>
  <div id="dashboard-content">
    <div class="d-flex flex-column flex-lg-row justify-content-between mb-3">
      <h4>Daftar Penilaian Buku</h4>
      <div class="mt-2 mt-lg-0 mb-4">
        <div class="dropdown">
          <router-link
            to="/user/agenda-penilaian"
            class="btn btn-sm btn-outline-secondary"
          >
            <a><i class="fa fa-upload mr-1"></i> Daftarkan Buku</a>
          </router-link>
        </div>
      </div>
    </div>

    <ul
      class="nav nav-pills nav-fill bg-white p-3 border-bottom"
      id="pills-tab-penilaian"
      role="tablist"
    >
      <li class="nav-item">
        <a
          class="nav-link active"
          id="pills-all-tab"
          data-toggle="pill"
          href="#pills-all"
          role="tab"
          aria-controls="pills-all"
          aria-selected="true"
          @click="setTab('allBooks')"
        >
          Semua
          <span class="border rounded-pill px-2 ml-2">
            {{ allBooks.length }}
          </span>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="pills-progress-tab"
          data-toggle="pill"
          href="#pills-progress"
          role="tab"
          aria-controls="pills-progress"
          aria-selected="true"
          @click="setTab('bookOnProgress')"
        >
          Dalam Proses
          <span class="border rounded-pill px-2 ml-2">
            {{ bookOnProgress.length }}
          </span>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="pills-success-tab"
          data-toggle="pill"
          href="#pills-success"
          role="tab"
          aria-controls="pills-success"
          aria-selected="false"
          @click="setTab('passBooks')"
        >
          Lolos Penilaian
          <span class="border rounded-pill px-2 ml-2">
            {{ passBooks.length }}
          </span>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="pills-not-success-tab"
          data-toggle="pill"
          href="#pills-not-success"
          role="tab"
          aria-controls="pills-not-success"
          aria-selected="false"
          @click="setTab('notPassBooks')"
        >
          Tidak Lolos
          <span class="border rounded-pill px-2 ml-2">
            {{ notPassBooks.length }}
          </span>
        </a>
      </li>
    </ul>

    <div class="tab-content border-0 card p-3" id="pills-tabPenilaian">
      <!-- Search book -->
      <div
        class="d-flex flex-column flex-md-row justify-content-between mb-3"
        style="gap:.5rem"
      >
        <div>
          <input
            class="form-control"
            type="text"
            placeholder="Cari Buku"
            v-model="search"
          />
        </div>
        <div class="filter-group">
          <date-range v-model="dateRange"></date-range>
          <div class="d-flex gap-1">
            <select
              @change="selectEvent($event.target.value)"
              class="custom-select agenda"
            >
              <option value="" selected>Semua Agenda</option>
              <option
                v-for="(event, index) in events"
                :key="index"
                :value="event.event_title"
                >{{ event.event_title }}</option
              >
            </select>
            <div
              v-if="displayView == 'card'"
              @click="displayView = 'table'"
              class="btn border d-flex align-items-center"
              style="height:38px;"
            >
              <span class="d-none d-lg-block pr-1">Table</span>
              <i class="fa fa-table"></i>
            </div>
            <div
              v-else
              @click="displayView = 'card'"
              class="btn border d-flex align-items-center"
              style="height:38px;"
            >
              <span class="d-none d-lg-block pr-1 d-flex align-items-center">Card</span>
              <i class="fa fa-layer-group"></i>
            </div>
          </div>
        </div>
      </div>
      <!-- End search book -->

      <div
        class="tab-pane fade show active"
        id="pills-all"
        role="tabpanel"
        aria-labelledby="pills-all-tab"
      >
        <div v-if="$store.state.loadBookPublisher" class="text-center py-4">
          <div
            class="spinner-border"
            style="width: 3rem; height: 3rem;"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div v-if="!filteredBooks.length && !$store.state.loadBookPublisher">
          <EmptyState title="Belum ada buku" />
        </div>
        <div v-else>
          <div v-if="displayView == 'table'" class="py-3">
            <Table :books="splittedBooks" @on-delete="() => getPublisher()" />
          </div>
          <div v-else v-for="(book, index) in splittedBooks" :key="index">
            <BookAssesment :book="book" @on-delete="() => getPublisher()" />
          </div>
          <div class="d-flex justify-content-center">
            <Pagination
              :key="totalPage + activeTab"
              :total-page="totalPage"
              :current-page.sync="pagination.currentPage"
              :total-data="filteredBooks.length"
              @change="onChangePage"
            >
            </Pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal History Revision Book-->
    <div
      class="modal fade"
      id="revisiModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="revisiModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="revisiModalLabel">Riwayat Buku</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="historyBooks.length == 0" class="text-center my-3">
              <img
                src="@/assets/not-found.png"
                class="mb-2"
                width="40"
                alt="Not found"
              />
              <div>Belum ada riwayat revisi buku</div>
            </div>
            <div v-else>
              <p>Berikut ini adalah riwayat perubahan pada buku.</p>
              <small>* Data diurutkan dari yang terbaru.</small>
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <th>Revisi</th>
                    <th>Diperbaharui pada</th>
                  </tr>
                  <tr v-for="book in splittedBooks" :key="book.id">
                    <td>
                      <span class="text-muted">ID</span> : {{ book.id }}<br />
                      <span class="text-muted">Judul</span> :
                      {{ book.payload.title || "-" }}<br />
                      <span class="text-muted">Mata Pelajaran</span> :
                      {{ book.payload.subject || "-" }}<br />
                      <span class="text-muted">Tipe Buku</span> :
                      {{ book.payload.book_type.toUpperCase() }}<br />
                      <span class="text-muted">Penulis</span> :
                      {{ book.payload.author || "-" }}<br />
                      <span class="text-muted">Editor</span> :
                      {{ book.payload.editor || "-" }}<br />
                      <span class="text-muted">Ilustrator</span> :
                      {{ book.payload.illustrator || "-" }}<br />
                      <span class="text-muted">Edisi</span> :
                      {{ book.payload.edition || "-" }}<br />
                      <span class="text-muted">Tahun Terbit</span> :
                      {{ book.payload.publication_year || "-" }}<br />
                      <span class="text-muted">ISBN</span> :
                      {{ book.payload.isbn || "-" }}<br />
                      <span class="text-muted">Jenjang</span> :
                      {{ book.payload.level || "-" }}<br />
                      <span class="text-muted">Total Halaman</span> : Prelim
                      {{ book.payload.total_prelim_page }}, Konten
                      {{ book.payload.total_content_page }}, Postlim
                      {{ book.payload.total_ending_page }}<br />
                      <span class="text-muted">File Original</span> :
                      {{ book.payload.file_original || "-" }}<br />
                      <span class="text-muted">File Tanpa Identitas</span> :
                      {{ book.payload.file_no_identity || "-" }}<br />
                      <span class="text-muted">File Capaian Pembelajaran</span>
                      : {{ book.payload.file_capaian_pembelajaran || "-"
                      }}<br />
                      <span class="text-muted">Surat Pernyataan Penulis</span> :
                      {{ book.payload.surat_pernyataan_penulis || "-" }}<br />
                      <span class="text-muted">Matrix Perbaikan</span> :
                      {{ book.payload.file_matrix_perbaikan || "-" }}
                      <button
                        v-show="book.payload.file_matrix_perbaikan"
                        type="button"
                        class="btn btn-link btn-sm"
                        @click="openFile(book.payload.file_matrix_perbaikan)"
                      >
                        Lihat Fail
                        <span class="fa fa-external-link"></span></button
                      ><br />
                    </td>
                    <td>{{ book.created_at }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import Pagination from "@/components/common/Pagination";
import axios from "axios";
import { ASSESMENT_URL } from "@/store";
import BookAssesment from "@/components/global/card/BookAssesment";
import EmptyState from "@/components/global/dashboard/EmptyState";
import Table from "@/components/global/card/Table";
import DateRange from "../../../components/common/DateRange.vue";
moment.locale("id");

export default {
  name: "Penilaian",
  components: { Pagination, BookAssesment, Table, EmptyState, DateRange },

  data() {
    return {
      name: "",
      search: "",
      events: [],
      books: [],
      booksPublisher: [],
      historyBooks: [],
      pagination: {
        currentPage: 1,
        maxPage: 5,
        pageSize: 10,
      },
      displayView: "card",
      activeTab: "allBooks",
      selectedEvent: "",
      dateRange: {
        startDate: moment(new Date())
          .subtract({ month: 2 })
          .format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
      },
    };
  },
  computed: {
    allBooks() {
      return this.booksPublisher.filter((book) =>
        book.title.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    bookOnProgress() {
      return this.booksPublisher.filter(
        (book) =>
          book.progress_status != "Lolos" &&
          book.title.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    passBooks() {
      return this.booksPublisher.filter(
        (book) =>
          book.progress_status == "Lolos" &&
          book.title.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    notPassBooks() {
      return this.booksPublisher.filter(
        (book) =>
          book.progress_status == "Tidak Lolos" &&
          book.title.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    filteredBooks() {
      return this[this.activeTab];
    },
    splittedBooks() {
      return this.filteredBooks.slice(
        (this.pagination.currentPage - 1) * this.pagination.pageSize,
        this.pagination.currentPage * this.pagination.pageSize
      );
    },
    totalPage() {
      return Math.ceil(this.filteredBooks.length / this.pagination.pageSize);
    },
  },
  watch: {
    search(val, oldVal) {
      if (val === oldVal) return;
      this.pagination.currentPage = 1;
    },
    dateRange: {
      handler() {
        this.pagination.currentPage = 1;
        this.getPublisher();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(["fetchBooksByPublisher"]),
    setTab(tab) {
      this.activeTab = tab;
      this.pagination.currentPage = 1;
    },
    selectEvent(event) {
      this.selectedEvent = event;
      this.pagination.currentPage = 1;
      this.booksPublisher = this.books.filter((book) =>
        this.selectedEvent ? book.event_title == this.selectedEvent : true
      );
    },
    openFile(name) {
      window.open(
        `https://static.buku.kemdikbud.go.id/uploader/files/event2/${name}`,
        `Read Popup Book`,
        `width=500,height=500`
      );
    },
    getHistoryBook(id) {
      axios
        .get(`${ASSESMENT_URL}/api/penilaian/book/revision/${id}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.historyBooks = response.data;
          }
        })
        .catch(() => (this.historyBooks = []));
    },
    checkStatus(status) {
      return status == "Lolos"
        ? "Lolos Penilaian"
        : status == "Menunggu"
        ? "Menunggu"
        : "Tidak Lolos";
    },
    convertDate(date) {
      return moment(date).format("LL");
    },
    onChangePage(page) {
      this.pagination.currentPage = page;
    },
    getPublisher() {
      this.fetchBooksByPublisher(this.dateRange).then((response) => {
        const books = response.data;
        this.books = books;
        this.booksPublisher = books;
        this.events = books.filter(
          (book, index) =>
            index ===
            books.findIndex((obj) => book.event_title === obj.event_title)
        );
      });
    },
  },
  created() {
    const { role_name } = JSON.parse(localStorage.getItem("user"));
    if (role_name) {
      if (role_name !== "Penerbit") this.$router.push("/user/home");
    } else {
      this.$router.push("/login");
    }
    this.getPublisher();
  },
  mounted() {
    scrollTo({ top: "0", behavior: "smooth" });
  },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/styles/_breakpoint.scss";

.filter-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  @include breakpoint("md") {
    flex-direction: row;
  }
  & .agenda {
    @include breakpoint("md") {
      max-width: 150px;
    }
  }
}

.gap-1 {
  gap: 0.5rem;
}
</style>
