<template>
  <div
    class="card mb-4 border-0 shadow-sm"
    :style="
      `${
        book.status != 'draft'
          ? 'background: #F6FCFF'
          : 'background: rgb(255, 239, 239)'
      }`
    "
  >
    <div class="card-body rounded p-3">
      <div class="row position-relative">
        <div class="col-lg-3">
          <img
            v-if="book.cover != null"
            :src="book.cover"
            class="w-100"
            style="border-radius: 10px;"
            alt="Cover"
          />
          <img
            v-else
            src="@/assets/book-placeholder.jpg"
            class="w-100"
            style="border-radius: 10px;"
            alt="Cover"
          />
        </div>
        <div class="col-lg-9">
          <div class="d-flex pt-2 pt-lg-0">
            <div class="text-muted mb-2 mr-auto">
              {{
                book.book_type === "btp" ? "Buku Teks Pendamping" :
                book.book_type === "bnt" ? "Buku Nonteks" : "Review Buku"
              }}
            </div>
            <div class="dropright">
              <button
                type="button"
                class="btn btn-sm rounded-0"
                style="background: transparent;"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa-solid fa-ellipsis fa-lg"></i>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <router-link
                  :to="`/user/penilaian/detail/${book.id}`"
                  class="dropdown-item d-flex"
                >
                  <div class="px-1 rounded-lg" style="background:#EEF4FF;">
                    <svg-icon name="eye" style="width:1.2rem"></svg-icon>
                  </div>
                  <span class="pl-2 text-grey-500">
                    Lihat Detail
                  </span>
                </router-link>
                <router-link
                  :to="
                    `/user/penilaian/${book.book_type}?id=${book.id}&event_id=${book.event_id}`
                  "
                  class="dropdown-item d-flex"
                >
                  <div class="px-1 rounded-lg" style="background:#FFF3D0;">
                    <svg-icon name="pen" style="width:1.2rem"></svg-icon>
                  </div>
                  <span class="pl-2 text-grey-500">
                    Edit/revisi buku
                  </span>
                </router-link>
                <router-link
                  :to="`/user/penilaian/cetak?id=${book.id}`"
                  class="dropdown-item d-flex"
                >
                  <div class="px-1 rounded-lg" style="background:#FFF0E6;">
                    <svg-icon name="printer" style="width:1.2rem"></svg-icon>
                  </div>
                  <span class="pl-2 text-grey-500">
                    Cetak Bukti
                  </span>
                </router-link>
                <button
                  v-if="book.status == 'publish'"
                  @click="setStatusBook({ id: book.id, status: 'draft' })"
                  class="dropdown-item d-flex"
                >
                  <div class="px-1 rounded-lg" style="background:#F2E1FF;">
                    <svg-icon
                      name="paper-dashed"
                      style="width:1.2rem"
                    ></svg-icon>
                  </div>
                  <span class="pl-2 text-grey-500">
                    Atur sebagai draft
                  </span>
                </button>
                <button
                  v-else
                  @click="setStatusBook({ id: book.id, status: 'publish' })"
                  class="dropdown-item d-flex"
                >
                  <div class="px-1 rounded-lg" style="background:#F2E1FF;">
                    <svg-icon
                      name="paper-dashed"
                      style="width:1.2rem"
                    ></svg-icon>
                  </div>
                  <span class="pl-2 text-grey-500">
                    Atur sebagai publish
                  </span>
                </button>
                <button
                  @click="deleteBook(book.id)"
                  class="dropdown-item d-flex text-danger"
                >
                  <div class="px-1 rounded-lg" style="background:#FFECEC;">
                    <svg-icon name="trash" style="width:1.2rem"></svg-icon>
                  </div>
                  <span class="pl-2 text-grey-500">
                    Hapus buku
                  </span>
                </button>
              </div>
            </div>
          </div>
          <router-link :to="`/user/penilaian/detail/${book.id}`" target="_blank" class="d-flex">
            <h4 class="mb-4" style="color: rgb(2, 161, 161);">
              {{ book.title }}
              <small v-show="book.status == 'draft'" class="text-danger ml-1">(Draft)</small>
            </h4>
            <i class="fa fa-external-link-alt ml-2" style="font-size: 15px;color: rgb(2, 161, 161);"></i>
          </router-link>

          <div class="row">
            <template v-if="book.book_type == 'btp'">
              <div class="col-lg-3 mb-2">
                <div class="text-primary">
                  <i class="fa fa-graduation-cap"></i>
                  Jenjang
                </div>
                <span>{{ book.level || "-" }}</span>
              </div>
              <div class="col-lg-4 mb-2">
                <div class="text-primary">
                  <i class="fa fa-book"></i>
                  Mata Pelajaran
                </div>
                <span>{{ book.subject || "-" }}</span>
              </div>
            </template>

            <template v-if="book.book_type == 'bnt'">
              <div class="col-lg-3 mb-2">
                <div class="text-primary">
                  <i class="fa fa-graduation-cap"></i>
                  Perjenjangan
                </div>
                <span>{{ book.level || "-" }}</span>
              </div>
              <div class="col-lg-3 mb-2">
                <div class="text-primary">
                  <i class="fa fa-book"></i>
                  Kategori
                </div>
                <span>{{ book.purpose || "-" }}</span>
              </div>
            </template>

            <div class="col-lg-5">
              <div class="text-primary">
                <i class="fa fa-chalkboard"></i>
                Agenda
              </div>
              <span>{{ book.event_title || "-" }}</span>
            </div>
          </div>

          <div class="d-flex mt-5 align-items-center">
            <router-link
              class="btn btn-secondary mr-3 text-white"
              :to="`/user/penilaian/hasil/${book.id}`"
            >
              Hasil Penilaian
            </router-link>
            <router-link
              class="btn btn-outline-secondary"
              :to="`/user/penilaian/revisi/${book.id}`"
            >
              Riwayat Revisi
            </router-link>
            <div class="text-muted ml-auto">
              <small
                >Dikirim pada tanggal {{ convertDate(book.created_at) }}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import SvgIcon from "@/components/common/SvgIcon.vue";

export default {
  props: ["book"],
  components: { SvgIcon },
  methods: {
    ...mapActions(["setStatusBook", "deleteBookByPublisher"]),
    convertDate(date) {
      return moment(date).format("LL");
    },
    deleteBook(id) {
      if (confirm("Apakah anda yakin akan menghapus ini?")) {
        this.deleteBookByPublisher(id).then(response => {
          if(response.data.status == 'success') {
            this.$emit('on-delete')
          }
        });
      }
    },
  },
};
</script>
