<template>
  <DateRangePicker
    :opens="direction"
    :date-range="dateRange"
    :max-date="disableFutureDates ? new Date() : null"
    @update="update"
    control-container-class="form-control border"
  >
    <template #input="picker">
      <div class="d-flex align-items-center pt-1">
        <i class="fa-regular fa-calendar"></i>
        <span class="small text-nowrap pl-2">
          {{ format(picker.startDate) }} - {{ format(picker.endDate) }}
        </span>
      </div>
    </template>
  </DateRangePicker>
</template>
<script>
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: { DateRangePicker },
  props: {
    value: {
      type: Object,
      required: true,
    },
    direction: {
      type: String,
      default: "left",
      validator(dir) {
        return ["left", "right", "center", "inline"].includes(dir);
      },
    },
    disableFutureDates: Boolean,
  },
  computed: {
    dateRange() {
      return this.value;
    },
  },
  methods: {
    update({ startDate, endDate }) {
      this.$emit("input", {
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
      });
    },
    format(date) {
      return moment(date).format("DD MMM YYYY");
    },
  },
};
</script>
<style>
.daterangepicker td.in-range,
.daterangepicker td.in-range:hover {
  background: var(--primary-100);
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background: var(--primary-500);
}

.applyBtn {
  background: var(--primary-500) !important;
}
</style>
