<template>
  <div class="table-responsive">
    <table class="table">
      <thead class="bg-grey-200">
        <tr>
          <th style="border-radius: 15px 0 0 0;">Kover</th>
          <th>Judul</th>
          <th>Jenjang</th>
          <th>Mata Pelajaran</th>
          <th>Agenda</th>
          <th>Tanggal Dikirim</th>
          <th style="border-radius: 0 15px 0 0;">Opsi</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="book in books" :key="book.id">
          <td style="border-left: 2px solid #dee2e6;">
            <img
              v-if="book.cover"
              :src="book.cover"
              width="50"
              class="rounded"
              alt="Cover"
            />
            <img
              v-else
              src="@/assets/book-placeholder.jpg"
              width="50"
              class="rounded"
              alt="Cover"
            />
          </td>
          <td>{{ book.title || "-" }}</td>
          <td>{{ book.level || "-" }}</td>
          <td>{{ book.subject || "-" }}</td>
          <td>{{ book.event_title || "-" }}</td>
          <td>{{ convertDate(book.created_at) || "-" }}</td>
          <td style="border-right: 2px solid #dee2e6;">
            <div class="dropright">
              <button
                type="button"
                class="btn btn-sm rounded-0"
                style="background: transparent;"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa-solid fa-ellipsis fa-lg"></i>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <router-link
                  :to="`/user/penilaian/detail/${book.id}`"
                  class="dropdown-item d-flex"
                >
                  <div class="px-1 rounded-lg" style="background:#EEF4FF;">
                    <svg-icon name="eye" style="width:1.2rem"></svg-icon>
                  </div>
                  <span class="pl-2 text-grey-500">
                    Lihat Detail
                  </span>
                </router-link>
                <router-link
                  :to="
                    `/user/penilaian/${book.book_type}?id=${book.id}&event_id=${book.event_id}`
                  "
                  class="dropdown-item d-flex"
                >
                  <div class="px-1 rounded-lg" style="background:#FFF3D0;">
                    <svg-icon name="pen" style="width:1.2rem"></svg-icon>
                  </div>
                  <span class="pl-2 text-grey-500">
                    Edit/revisi buku
                  </span>
                </router-link>
                <router-link
                  :to="`/user/penilaian/cetak?id=${book.id}`"
                  class="dropdown-item d-flex"
                >
                  <div class="px-1 rounded-lg" style="background:#FFF0E6;">
                    <svg-icon name="printer" style="width:1.2rem"></svg-icon>
                  </div>
                  <span class="pl-2 text-grey-500">
                    Cetak Bukti
                  </span>
                </router-link>
                <button
                  v-if="book.status == 'publish'"
                  @click="setStatusBook({ id: book.id, status: 'draft' })"
                  class="dropdown-item d-flex"
                >
                  <div class="px-1 rounded-lg" style="background:#F2E1FF;">
                    <svg-icon
                      name="paper-dashed"
                      style="width:1.2rem"
                    ></svg-icon>
                  </div>
                  <span class="pl-2 text-grey-500">
                    Atur sebagai draft
                  </span>
                </button>
                <button
                  v-else
                  @click="setStatusBook({ id: book.id, status: 'publish' })"
                  class="dropdown-item d-flex"
                >
                  <div class="px-1 rounded-lg" style="background:#F2E1FF;">
                    <svg-icon
                      name="paper-dashed"
                      style="width:1.2rem"
                    ></svg-icon>
                  </div>
                  <span class="pl-2 text-grey-500">
                    Atur sebagai publish
                  </span>
                </button>
                <button
                  @click="deleteBook(book.id)"
                  class="dropdown-item d-flex text-danger"
                >
                  <div class="px-1 rounded-lg" style="background:#FFECEC;">
                    <svg-icon name="trash" style="width:1.2rem"></svg-icon>
                  </div>
                  <span class="pl-2 text-grey-500">
                    Hapus buku
                  </span>
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import SvgIcon from '../../common/SvgIcon.vue';

export default {
  components: { SvgIcon },
  props: ["books"],
  methods: {
    ...mapActions(["setStatusBook", "deleteBookByPublisher"]),
    convertDate(date) {
      return moment(date).format("LL");
    },
    deleteBook(id) {
      if (confirm("Apakah anda yakin akan menghapus ini?")) {
        this.deleteBookByPublisher(id).then(response => {
          if(response.data.status == 'success') {
            this.$emit('on-delete')
          }
        });
      }
    },
  },
};
</script>
